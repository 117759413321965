import Vue from "vue";
import Router from "vue-router";

import i18n from "@/i18n/index.js";

import has from "lodash/has";

import EmptyRouterView from "@/views/EmptyRouterView";
import Home from "@/views/Home";
import AboutUs from "@/views/AboutUs";
import PhotoStudio from "@/views/PhotoStudio";
import DesignReflection from "@/views/DesignReflection";
import DesignReflectionList from "@/views/DesignReflectionList";
import DesignReflectionDetail from "@/views/DesignReflectionDetail";
import Atelier from "@/views/Atelier";
import AtelierDetail from "@/views/AtelierDetail";
import DigitalApplications from "@/views/DigitalApplications";
import DigitalApplicationsLanding from "@/views/DigitalApplicationsLanding";

import Lng from "@/views/Lng";
import Error404 from "@/views/Error404";

Vue.use(Router);

const router = new Router({
	// mode: "history",
	linkActiveClass: "active",
	routes: [
		{
			path: "/:lng",
			component: {
				render: h => h("router-view")
			},
			meta: {},
			children: [
				{
					path: "",
					name: "home",
					component: Home,
					meta: {
						hide_header: true
					}
				},
				{
					path: "about-us",
					name: "about_us",
					component: AboutUs,
					meta: {
						title: "About Us",
						color: "blue"
					}
				},
				{
					path: "photo-studio",
					name: "photo_studio",
					component: PhotoStudio,
					meta: {
						title: "Photo Studio",
						color: "red"
					}
				},
				{
					path: "design-reflection",
					name: "design_reflection",
					component: DesignReflection,
					meta: {
						title: "Design Reflection",
						color: "yellow"
					}
				},
				{
					path: "design-reflection/list",
					name: "design_reflection_list",
					component: DesignReflectionList,
					meta: {
						title: "Design Reflection",
						color: "yellow"
					}
				},
				{
					path: "design-reflection/:slug",
					name: "design_reflection_detail",
					component: DesignReflectionDetail,
					meta: {
						title: "Design Reflection",
						color: "yellow"
					}
				},
				{
					path: "atelier",
					name: "atelier",
					component: Atelier,
					meta: {
						title: "Atelier",
						color: "pink"
					}
				},
				{
					path: "atelier/:slug",
					name: "atelier_detail",
					component: AtelierDetail,
					meta: {
						title: "Atelier",
						color: "pink"
					}
				},
				{
					path: "digital-applications",
					name: "digital_applications_landing",
					component: DigitalApplicationsLanding,
					meta: {
						title: "Digital Applications",
						color: "green"
					}
				},
				{
					path: "digital-applications/more",
					name: "digital_applications",
					component: DigitalApplications,
					meta: {
						title: "Digital Applications",
						color: "green"
					}
				},
				{
					path: "lng",
					name: "lng",
					component: Lng,
					meta: {
						hide_header: true
					}
				}
			]
		}
		// {
		// 	path: "*",
		// 	redirect: "404",
		// 	meta: {}
		// }
	]
});

router.beforeEach((to, from, next) => {
	const lng = to.params.lng;

	if (!["en", "pt"].includes(lng)) {
		console.log(`/${i18n.locale}/404`);
		return next({ path: `/${i18n.locale}/404` });
	}

	if (i18n.locale !== lng) {
		i18n.locale = lng;
		window.localStorage.setItem("current_language", lng);
	}

	if (new RegExp(`^/${i18n.locale}$`).test(to.path) || new RegExp(`^/${i18n.locale}/`).test(to.path)) {
		next();
	} else {
		next({ path: `/${i18n.locale}${to.path.substring(3)}` });
	}
});

// router.beforeEach((to, from, next) => {
// 	let language = to.params.lang;
// 	if (!language) {
// 		language = "en";
// 	}

// 	i18n.locale = language;
// 	window.localStorage.setItem("current_language", language);

// 	next();
// });

// router.beforeEach((to, from, next) => {
// 	if (new RegExp(`^/${i18n.locale}$`).test(to.path) || new RegExp(`^/${i18n.locale}/`).test(to.path)) {
// 		console.log("1");
// 		next();
// 	} else {
// 		let newPath = `/${i18n.locale}${to.path}`;
// 		const toSplit = to.path.split("/");
// 		console.log(toSplit);
// 		if (toSplit[1] !== i18n.locale) {
// 			toSplit[1] = i18n.locale;
// 			newPath = toSplit.join("/");
// 			console.log(newPath);
// 			next({ path: newPath });
// 		} else {
// 			next({ path: newPath });
// 		}
// 	}
// });

// router.beforeEach((to, from, next) => {
// 	console.log(to);
// 	let resolved = router.resolve({ name: to.name });
// 	if (resolved.route.name != "404") {
// 		if (has(to.meta, "locale")) {
// 			if (has(to.meta, "is_home")) {
// 				next();
// 			} else {
// 				if (has(to.meta, "base_translatable_url")) {
// 					next({ name: `${to.name}_${i18n.locale}` });
// 				} else {
// 					if (resolved.route.meta.locale !== i18n.locale) {
// 						next({ name: `${to.name.slice(0, -3)}_${i18n.locale}` });
// 					} else {
// 						next();
// 					}
// 				}
// 			}
// 		} else {
// 			next({ name: "404" });
// 		}
// 	} else {
// 		next();
// 	}
// });

export default router;
