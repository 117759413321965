<template>
  <div class="body-wrapper" :data-color="$route.meta.color">
    <site-header v-if="!$route.meta.hide_header"></site-header>
    <main class="site-main" role="main">
        <router-view></router-view>
    </main>
  </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import SiteHeader from "@/components/SiteHeader";

    export default {
        name: "App",
        components: {
            SiteHeader,
        },
    };
</script>
