<template>
    <section class="page-content atelier-detail-content" v-if="project">
        <div class="atelier-detail-intro">
            <div class="main">
                <thumbnailer
                    :css-classes="'image'"
                    :filer-file-id="project.image.id"
                    :size="'1500x0'"
                    :size_xs="'460x0'"
                    :size_md="'750x0'"
                    :key="project.image.id" />
                <!-- <picture class="image">
                    <img v-bind:src="project.image.url" alt="">
                </picture> -->
            </div>
            <div class="aside">
                <!-- <ul class="info" v-if="project.info">
                    <li v-for="(value, name, index) in project.info" :key="index">
                        <strong>{{ name }}</strong> {{ value }}
                    </li>
                </ul> -->
                <ul class="info">
                    <!-- TODO: Translation -->
                    <li v-if="project.client">
                        <strong>{{ $t('message.client') }}</strong> {{project.client.name}}
                    </li>
                    <li v-if="project.category">
                        <strong>{{ $t('message.category') }}</strong> {{project.category.name}}
                    </li>
                    <li v-if="project.award">
                        <strong>{{ $t('message.award') }}</strong> {{project.award}}
                    </li>
                    <li v-if="project.year">
                        <strong>{{ $t('message.year') }}</strong> {{project.year}}
                    </li>
                </ul>
                <!-- <picture class="thumb">
                    <img v-bind:src="project.secondary_image.url" alt="">
                </picture> -->
                    <thumbnailer
                        :css-classes="'thumb'"
                        :filer-file-id="project.secondary_image.id"
                        :size="'800x0'"
                        :size_xs="'210x0'"
                        :size_md="'360x0'"
                        :key="project.secondary_image.id" />
            </div>
        </div>

        <div class="atelier-detail-description" v-if="project.description">
            <h1 class="title" v-html="project.title"></h1>
            <div class="text" v-html="project.description"></div>
        </div>

        <div class="atelier-detail-gallery" v-if="project.slideshow">
            <swiper class="slider" :options="sliderOptions">
                <swiper-slide v-for="item in project.slideshow.get_slides" :key="item.id">
                    <thumbnailer
                        :css-classes="'image'"
                        :filer-file-id="item.image.id"
                        :size="'3000x0'"
                        :size_xs="'480x0'"
                        :size_sm="'768x0'"
                        :size_md="'1200x0'"
                        :key="item.image.id" />
                </swiper-slide>
                <div class="slider-button-prev" slot="button-prev"></div>
                <div class="slider-button-next" slot="button-next"></div>
            </swiper>
        </div>

        <div class="projects-nav" v-if="projects.length">
            <tiny-slider
                :nav=false
                :controls=true
                :controls-text='["", ""]'
                :mouse-drag=true
                items="4"
                :responsive= "{768: { items: 9 }}">
                <div
                    v-for="item in projects"
                    :key="item.id">
                    <router-link
                        :to="{ name: 'atelier_detail', params: { slug: item.slug }}">
                        <picture class="image">
                            <img :src="item.thumbnail_image.url" alt="">
                        </picture>
                        <!-- <thumbnailer
                            :css-classes="'image'"
                            :filer-file-id="item.thumbnail_image.id"
                            :size="'100x100'"
                            :size_sm="'60x60'"
                            :key="item.thumbnail_image.id" /> -->
                    </router-link>
                </div>
            </tiny-slider>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import VueTinySlider from 'vue-tiny-slider';
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
    import 'swiper/css/swiper.css'

    export default{
        components: {
            'tiny-slider': VueTinySlider,
            Swiper,
            SwiperSlide,

        },
        directives: {
            swiper: directive
        },
        data(){
            return{
                slug: this.$route.params.slug,
                project: undefined,
                sliderOptions: {
                    slidesPerView: 1,
                    loop: true,
                    navigation: {
                        nextEl: '.slider-button-next',
                        prevEl: '.slider-button-prev'
                    }
                },
            }
        },
        async created() {
            this.loadProject(this.$route.params.slug);
            this.get_projects_list();
        },
        watch: {
            $route: function(to, from) {
                // Scroll to top
                window.scroll({
                    top: to,
                    left: 0,
                    behavior: 'smooth'
                });
                // load project
                this.slug = to.params.slug;
                this.loadProject(this.slug);
            },
        },

        computed: {
            ...mapGetters(["projects"]),
        },
        methods: {
            ...mapActions(["get_projects_list"]),
            async loadProject(slug){
                await this.$api.atelier.atelier_read({
                    slug: this.$route.params.slug
                }).then(async res => {
                    if (res.status !== 200) {
                        throw res;
                    }
                    this.project = res.obj;
                });

                return undefined;
            }
        },
    };
</script>