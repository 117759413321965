import ApiService from "@/services/api.service.js";

const state = {
	languages: []
};
const getters = {
	languages(state) {
		return state.languages;
	}
};
const mutations = {
	setLanguages(state, languages) {
		state.languages = languages;
	}
};
const actions = {
	async getLanguages({ commit }) {
		try {
			const result = await ApiService.api.languages.languages_list();
			if (result.status !== 200) {
				throw result;
			}

			commit("setLanguages", result.obj);
		} catch (err) {
			console.log(err);
			languages = [
				{ value: "pt", text: "Português" },
				{ value: "en", text: "English" }
			];
			commit("setLanguages", languages);
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
