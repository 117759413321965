import Vue from "vue";
import Vuex from "vuex";

import languages from "@/store/languages.store.js";
import projects from "@/store/projects.store.js";
import articles from "@/store/articles.store.js";
import photostudio from "@/store/photostudio.store.js";
import common from "@/store/common.store.js";

Vue.use(Vuex);
export default new Vuex.Store({
	modules: {
		languages,
		projects,
		articles,
		photostudio,
		common
	}
});
