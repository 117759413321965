<template>
	<section class="page-content articles-detail-content" v-if="article">
		<div class="loader" v-bind:class="{ inactive: loaded }"></div>
		<div class="articles-detail">
			<div class="articles-detail-intro">
				<!-- <picture class="image">
                    <img :src="article.image.url" alt="">
                </picture> -->
				<thumbnailer
					:css-classes="'image'"
					:filer-file-id="article.image.id"
					size="1330x0"
					:size_xs="'350x0'"
					:size_md="'560x0'"
					:key="article.image.id"
					v-if="article.image"
				/>
				<blockquote>
					<p>{{ article.quote }}</p>
				</blockquote>
			</div>
			<div class="articles-detail-content" v-if="contents">
				<h1>{{ article.title }}</h1>
				<!-- <div v-html="article.text"></div> -->
				<div class="col" :class="'col-' + item.orientation" v-for="(item, index) in contents" :key="index">
					<span v-html="item.text" v-if="item.text"></span>
					<thumbnailer
						:css-classes="'image'"
						:filer-file-id="item.image.id"
						size="1330x0"
						:size_xs="'370x0'"
						:size_md="'610x0'"
						:key="item.image.id"
						v-if="item.image"
					/>
				</div>
				<!-- <div class="col col-right">
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
                </div>
                <div class="col col-left">
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.</p>
                </div>
                <div class="col col-right">
                    <picture>
                        <img src="https://via.placeholder.com/546x350" alt="" />
                    </picture>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.</p>
                </div> -->
			</div>
		</div>

		<div class="articles-detail-aside" v-if="related_articles">
			<form action="" class="search-form">
				<input type="text" :placeholder="$t('message.search')" />
			</form>

			<h2>{{ $t("message.recent_posts") }}</h2>
			<ul class="recent-articles">
				<li v-for="(item, index) in related_articles" :key="index">
					<router-link :to="{ name: 'design_reflection_detail', params: { slug: item.slug } }">
						<!-- <picture class="image">
                            <img :src="item.image.url" alt="">
                        </picture> -->
						<thumbnailer
							css-classes="image"
							:filer-file-id="item.image.id"
							:size="'150x150'"
							:crop="true"
							:upscale="true"
							:key="item.image.id"
						/>
						<div class="description">
							<h3>{{ item.title }}</h3>
							<p v-if="item.description">{{ item.description }}</p>
							<div class="date">{{ item.formatted_date }}</div>
						</div>
					</router-link>
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			slug: this.$route.params.slug,
			article: undefined,
			contents: undefined,
			related_articles: undefined,
			loaded: false
		};
	},
	async created() {
		await this.$api.blog.blog_list_page().then((res) => {
			if (res.status !== 200) {
				throw res;
			}
			if (!res.obj.published) {
				this.$router.push({ name: "404" });
			}
		});
		this.loadArticle(this.$route.params.slug);
		this.loadRelatedArticles(this.$route.params.slug);
	},
	watch: {
		$route: function (to, from) {
			// Scroll to top
			window.scroll({
				top: to,
				left: 0,
				behavior: "smooth"
			});
			// load project
			this.slug = to.params.slug;
			this.loadArticle(this.slug);
			this.loadRelatedArticles(this.$route.params.slug);
		}
	},
	methods: {
		async loadArticle(slug) {
			await this.$api.blog
				.blog_read({
					slug: this.$route.params.slug
				})
				.then(async (res) => {
					if (res.status !== 200) {
						throw res;
					}
					this.article = res.obj;

					await this.$api.blog
						.blog_get_content({
							slug: this.$route.params.slug
						})
						.then(async (res) => {
							if (res.status !== 200) {
								throw res;
							}
							this.contents = res.obj;
							this.loaded = true;
						});
				});

			return undefined;
		},
		async loadRelatedArticles(excludedArticle) {
			await this.$api.blog
				.blog_recent_posts({
					exclude_slug: this.$route.params.slug
				})
				.then(async (res) => {
					if (res.status !== 200) {
						throw res;
					}
					this.related_articles = res.obj;
				});

			return undefined;
		},
		positionClass(item) {
			return "col-" + item.orientation;
		}
	}
};
</script>