<template>
	<section class="page-content articles-content">
		<div class="loader" v-bind:class="{ inactive: loaded }"></div>
		<div class="articles-list" v-if="articles.length">
			<article v-for="(item, index) in articles" :key="index" class="articles-item">
				<router-link :to="{ name: 'design_reflection_detail', params: { slug: item.slug } }">
					<!-- <div class="image" v-if="item.image" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"></div> -->
					<thumbnailer
						:css-classes="'image'"
						:filer-file-id="item.image.id"
						:size="'600x600'"
						:size_md="'260x260'"
						:key="item.image.id"
					/>
					<div class="description">
						<h2>{{ item.title }}</h2>
						<p v-if="item.caption">{{ item.caption }}</p>
						<div class="date">{{ item.formatted_date }}</div>
					</div>
				</router-link>
			</article>
		</div>
	</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			loaded: false
		};
	},
	async created() {
		const res = await this.$api.blog.blog_list_page();
		if (res.status !== 200) {
			throw res;
		}
		if (!res.obj.published) {
			this.$router.push({ name: "404" });
		}
		this.digital_applications = res.obj;
		this.get_articles_list().then((response) => {
			this.loaded = true;
		});
	},

	computed: {
		...mapGetters(["articles"])
	},
	methods: {
		...mapActions(["get_articles_list"])
	}
};
</script>