import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from "@/services/api.service.js";

Vue.use(Vuex);

const state = {
    projects: [],
    project: undefined
};
const getters = {
    projects: state => {
        // Array
        return state.projects
    },
    project: state => {
        // Object
        return state.project
    },
};

const actions = {
    get_projects_list: async function({ commit }){
        return await ApiService.api.atelier.atelier_list().then(async res => {
            if (res.status !== 200) {
                throw res;
            }

            let projectList = []
            for(const apiProject of res.obj){
            // let photost = res.obj.map(api_gallery => {
                let projectObj = {
                    id: apiProject.id,
                    slug: apiProject.slug,
                    thumb: apiProject.image.url,
                    image: apiProject.image,
                    thumbnail_image: apiProject.thumbnail_image,
                };

                const imageResponse = await ApiService.api.get_thumb.get_thumb_read({
                    id: apiProject.image.id,
                    size: "1200x1200",
                    crop: true,
                    upscale: true
                });
                if (imageResponse.status === 200) {
                    projectObj.thumb = imageResponse.obj.url;
                }

                projectList.push(projectObj);
            };

            commit("projects_list_mutation", projectList);
            return res.obj
        });
    },
    get_project_details: async function({ commit }, projectId = undefined){
        return await ApiService.api.atelier.atelier_read({
            id: projectId
        }).then(async res => {
            if (res.status !== 200) {
                throw res;
            }

            commit("project_details_mutation", res.obj);
            return res.obj
        });

    }
};

const mutations = {
    projects_list_mutation: function(state, obj){
        state.projects = obj;
    },
    project_details_mutation: function(state, obj){
        state.project = obj;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};