import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from "@/services/api.service.js";

Vue.use(Vuex);

const state = {
    photostudio: []
};

const getters = {
    photostudio: state => {
        return state.photostudio
    },
};

const actions = {
    get_photostudio_list: async function({ commit }){
        // await this.$api.photostudio.photostudio_list({page: 1});
        return await ApiService.api.photostudio.photostudio_list().then(async res => {
            if (res.status !== 200) {
                throw res;
            }
            // return res.body.results
            let photost = []
            for(const apiGallery of res.obj){
            // let photost = res.obj.map(api_gallery => {
                let images = [];
                let randomImageIdx = Math.floor(Math.random() * apiGallery.images.length);
                let count = 0;
                for(const slide of apiGallery.images){
                    let slideObj = {
                        // image: slide.image.url,
                        image_id: slide.image.id,
                        image_thumb_small: undefined,
                        // image_thumb_big: undefined,
                        // title: slide.title,
                        // description: slide.description,
                    };
                    let image_thumb_small = undefined;
                    if(count == randomImageIdx){
                        image_thumb_small = await ApiService.api.get_thumb.get_thumb_read({
                            id: slide.image.id,
                            size: "800x0",
                            crop: false,
                            upscale: true
                        });
                        slideObj.image_thumb_small = image_thumb_small.obj.url;
                    };
                    // const image_thumb_big = ApiService.api.get_thumb.get_thumb_read({
                    //     id: slide.image.id,
                    //     size: "1920x0",
                    //     crop: false,
                    //     upscale: true
                    // });

                    // // TODO: Optimize
                    // if(image_thumb_small){
                    //     let values = await Promise.all([image_thumb_small, image_thumb_big]);
                    //     slideObj.image_thumb_small = values[0].obj.url;
                    //     slideObj.image_thumb_big = values[1].obj.url;
                    // }else{
                    //     let values = await Promise.all([image_thumb_big]);
                    //     slideObj.image_thumb_big = values[0].obj.url;
                    // };

                    images.push(slideObj);
                    count++;
                };
                // let randomImageIdx = Math.floor(Math.random() * images.length)
                let gallery = {
                    id: apiGallery.id,
                    images: images,
                    randomImageIdx: randomImageIdx
                };
                photost.push(gallery)
            };

            commit("photost_mutation", photost);
            return res.obj
        })
        .catch(err => {
            console.log("error - list fetch", err);
            return err;
        });

    }
}

const mutations = {
    photost_mutation: function(state, obj){
        state.photostudio = obj;
    }
};



export default {
    state,
    getters,
    actions,
    mutations
};