<template>
	<section class="page-content atelier-content">
		<div class="loader" v-bind:class="{ inactive: loaded }"></div>
		<div class="projects-list" v-if="projects.length">
			<tiny-slider
				ref="tinySlider"
				:nav="false"
				:controls="true"
				:mouse-drag="true"
				:center="true"
				items="1"
				:disable="true"
				:controls-text="['', '']"
				:responsive="{ 768: { disable: false } }"
			>
				<div v-for="item in projects" :key="item.id" class="projects-item">
					<router-link :to="{ name: 'atelier_detail', params: { slug: item.slug } }">
						<!-- <thumbnailer
                            :css-classes="'image'"
                            :filer-file-id="item.thumbnail_image.id"
                            :size="'1500x1500'"
                            :size_sm="'190x190'"
                            :size_md="'580x580'"
                            :key="item.thumbnail_image.id" /> -->
						<picture class="image">
							<img :src="item.thumbnail_image.url" alt="" />
						</picture>
						<!-- <div class="image" v-bind:style="{ backgroundImage: 'url(' + item.thumb + ')' }"></div> -->
					</router-link>
				</div>
			</tiny-slider>
		</div>
	</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueTinySlider from "vue-tiny-slider";

export default {
	components: {
		"tiny-slider": VueTinySlider
	},

	data() {
		return {
			loaded: false
		};
	},

	async created() {
		await this.$api.atelier.atelier_list_page().then((res) => {
			if (res.status !== 200) {
				throw res;
			}
			if (!res.obj.published) {
				this.$router.push({ name: "404" });
			}
		});
		this.get_projects_list().then((response) => {
			this.loaded = true;
		});
	},

	computed: {
		...mapGetters(["projects"])
	},
	methods: {
		...mapActions(["get_projects_list"])
	}
};
</script>