
export const messages = {
    en: {
        message: {
        recent_posts: 'Recent Posts',
        search: 'Search',
        client: 'Client',
        category: 'Category',
        award: 'Award',
        year: 'Date',
        }
    },
    pt: {
        message: {
        recent_posts: 'Posts Recentes',
        search: 'Pesquisar',
        client: 'Cliente',
        category: 'Categoria',
        award: 'Prémio',
        year: 'Data',
        }
    }
}

export default messages;