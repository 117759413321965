import Swagger from "swagger-client";

//console.log(client.spec);			//The resolved spec.
//console.log(client.originalSpec);	//In case you need it.
//console.log(client.errors);		//Any resolver errors.

function client_header_injector(req) {
	if (!req.headers["X-CSRFToken"]) {
		req.headers["x-CSRFToken"] = window.getCookie("csrftoken");
	}
	if (!req.headers["Accept-Language"]) {
		req.headers["Accept-Language"] = window.localStorage.getItem("current_language");
	}
}

async function getClient() {
	return await Swagger({
		url: "/swagger.json",
		requestInterceptor: req => client_header_injector(req)
	});
}

const ApiService = {
	client: {},
	api: {},
	async init() {
		this.client = await getClient();
		this.api = this.client.apis;
	}
};

export default ApiService;
