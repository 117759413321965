<template>
    <section class="page-content about-content" v-if="about">
        <div class="about-intro">
            <div class="about-shape">
                <img src="/static/site/images/about-shape.svg" alt />
            </div>
            <h1>
                <span v-for="subtitle in linebreakssplit(about.subtitle)" :key="subtitle">{{ subtitle }}</span>
            </h1>
            <h3 v-html="about.details"></h3>
        </div>

        <div class="about-text">
            <!-- <div class="col">
                <h2>Our Working Beliefs</h2>
                <p>
                    No nosso atelier acreditamos que é possível chegar a um
                    <strong>compromisso</strong> entre cliente e designer, através de um processo criativo que
                    procura conhecer e manter o respeito pela identidade da marca.
                </p>
            </div>-->
            <div class="col" v-html="about.text_1"></div>
            <!-- <div class="col">
                <p>
                    Abraçamos todos os desafios que nos são impostos com vontade de inovar e chegar a um resultado
                    não só apelativo como capaz de comunicar com o público alvo.
                </p>
            </div>-->
            <div class="col" v-html="about.text_2"></div>
            <!-- <div class="col">
                <p>
                    No seguimento das alterações climáticas, também nós procuramos soluções para um design
                    sustentável, procurando conhecer novas alternativas e dá-las a conhecer aos nossos clientes.
                </p>
            </div>-->
            <div class="col" v-html="about.text_3"></div>
        </div>

        <div class="about-contacts">
            <div class="map-container">
                <a :href="about.map_link" target="_blank">
                    <thumbnailer
                        :css-classes="'map'"
                        :filer-file-id="about.map_image.id"
                        size="1200x1200"
                        :size_xs="'200x200'"
                        :size_sm="'320x320'"
                        :size_md="'600x600'"
                        :key="about.map_image.id"
                        v-if="about.map_image.id" />
                    <!-- <picture class="map">
                        <img v-if="map_thumbnail" :src="map_thumbnail" alt />
                    </picture> -->
                </a>
            </div>
            <div class="text">
                <div v-html="about.contacts_text"></div>

                <div class="social-links">
                    <a v-for="link in social_links" :key="link.id" :href="link.link" target="_blank">
                        <img :src="link.image.url" alt />
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import commonMixins from "@/mixins/common-mixins.js";

export default {
    mixins: [commonMixins],

    data() {
        return {
            about: null,
            map_thumbnail: null
        };
    },
    computed: {
        // subtitles() {
        //     return this.$data.about.subtitle.split("\n");
        // },
        social_links() {
            return this.$data.about.social_links.sort((a, b) => (a.order > b.order ? 1 : -1));
        }
    },
    async mounted() {
        try {
            /* To get api names (i.e. `about_list`), check /swagger/. Inside each
             * section there's an url and the name is to the right of that url. To
             * retrieve information using parameters (i.e. ID),
             * check mixins/common-mixin.js and test which parameters the URL accepts
             * by using /swagger/
             */

            const result = await this.$api.about.about_list();
            if (result.status !== 200) {
                throw result;
            }

            this.$data.about = result.obj;
            this.$data.map_thumbnail = await this.getThumbnailUrl(result.obj.map_image.id, "600x600");
        } catch (err) {
            console.log(err);
        }
    }
};
</script>
