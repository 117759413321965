import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from "@/services/api.service.js";

Vue.use(Vuex);

const state = {
    articles: []
};
const getters = {
    articles: state => {
        // Array
        return state.articles
    },
};

const actions = {
    get_articles_list: async function({ commit }){
        return await ApiService.api.blog.blog_list().then(async res => {
            if (res.status !== 200) {
                throw res;
            }

            let articleList = []
            for(const apiArticle of res.obj){
            // let photost = res.obj.map(api_gallery => {
                let articleObj = {
                    title: apiArticle.title,
                    slug: apiArticle.slug,
                    date: apiArticle.date,
                    image: apiArticle.image,
                    quote: apiArticle.quote,
                };

                const imageResponse = await ApiService.api.get_thumb.get_thumb_read({
                    id: apiArticle.image.id,
                    size: "600x600",
                    crop: true,
                    upscale: true
                });
                if (imageResponse.status === 200) {
                    articleObj.thumb = imageResponse.obj.url;
                }

                articleList.push(articleObj);
            };

            commit("articles_list_mutation", articleList);
            return res.obj
        });
    },
};

const mutations = {
    articles_list_mutation: function(state, obj){
        state.articles = obj;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};