<template>
    <ul class="language-select" v-if="languages">
        <li v-for="l in languages" :key="l.value" :class="[l.value === current_language ? 'selected' : '']">
            <a @click="setLanguage(l.value)">{{ l.value }}</a>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("languages", ["languages"]),
        current_language() {
            return this.$ls.get("current_language", "en");
        }
    },
    methods: {
        setLanguage(value) {
            this.$ls.set("current_language", value);
            // this.$router.go();
            // console.log(this.$route);
            // console.log(value);
            this.$router.push({ name: this.$route.name, params: { lng: value } });
            location.reload();
        }
    }
};
</script>
