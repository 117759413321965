<template>
	<section class="page-content about-content">
		<div class="about-intro">
			<h1><span>404</span><span>Page not found</span></h1>
		</div>
	</section>
</template>

<script>
export default {};
</script>
