<template>
	<header class="site-header">
		<div class="container">
			<div class="site-brand">
				<router-link to="/" v-if="conf != undefined">{{ conf.site_name }}</router-link>
			</div>

			<div class="page-title" v-if="$route.meta.title">{{ $route.meta.title }}</div>

			<language-select></language-select>

			<a href="#" v-on:click="showNav = !showNav" v-bind:class="{ active: showNav }" class="site-nav-btn"></a>

			<nav class="site-nav" v-bind:class="{ opened: showNav }">
				<main-menu v-on:toggle-nav="showNav = !showNav"></main-menu>
			</nav>
		</div>
	</header>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import LanguageSelect from "@/components/LanguageSelect";
import { mapGetters } from "vuex";

export default {
	name: "Header",
	components: {
		MainMenu,
		LanguageSelect
	},
	computed: {
		...mapGetters("languages", ["languages"]),
		...mapGetters("common", ["conf"]),
		current_language() {
			return this.$ls.get("current_language", "en");
		}
	},
	data: function () {
		return {
			showNav: false
		};
	}
};
</script>