<template>
	<section class="homepage-banner">
		<div class="site-logo" v-bind:class="{ 'hidden-logo': showMenu }">
			<img src="/static/site/images/logo.svg" alt="" />
		</div>

		<div class="homepage-menu" v-bind:class="{ 'visible-menu': showMenu }">
			<h1 class="site-title">{{ conf.site_name }}</h1>
			<main-menu withMenuPosition :showMenu="showMenu"></main-menu>
		</div>
	</section>
</template>


<script>
import MainMenu from "@/components/MainMenu";
import { mapGetters } from "vuex";

export default {
	name: "Home",
	components: {
		MainMenu
	},
	computed: {
		...mapGetters("common", ["conf"])
	},
	data: function () {
		return {
			showMenu: false
		};
	},
	mounted() {
		// Show menu after timeout
		setTimeout((_) => (this.showMenu = true), 2100);
	}
};
</script>