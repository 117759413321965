export default {
	methods: {
		async getThumbnailUrl(id, size_str, crop = false, upscale = true, quality = 90) {
			let url = "";
			const result = await this.$api.get_thumb
				.get_thumb_read({
					id: id,
					size: size_str,
					crop: crop,
					upscale: upscale,
					quality: quality
				})
				.then(response => {
					url = response.obj.url;
				})
				.catch(error => {
					console.log(error.response);
				});

			// console.log(url);
			return url;
		},
		linebreaksbr(str) {
			return str.replace(/(\r\n|\n)/g, '<br/>');
		},
		linebreakssplit(str) {
			return str.split('\n');
		},
	}
};
