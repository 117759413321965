<template>
	<section class="page-content apps-content" v-if="digital_applications">
		<div class="apps-intro">
			<h1>
				<span v-for="subtitle in linebreakssplit(digital_applications.subtitle)" :key="subtitle">{{
					subtitle
				}}</span>
			</h1>
			<h3 v-if="digital_applications.details" v-html="digital_applications.details"></h3>
		</div>

		<div class="apps-video" v-if="digital_applications.video_embed_url">
			<iframe
				ref="reframeVideo"
				:src="digital_applications.video_embed_url"
				width="640"
				height="360"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
			></iframe>
		</div>

		<div class="apps-details" v-if="digital_applications.case_study">
			<div class="text">
				<div class="col col-left">
					<h2>{{ digital_applications.case_study.title }}</h2>
					<div
						v-if="digital_applications.case_study.text_1"
						v-html="digital_applications.case_study.text_1"
					></div>
				</div>
				<div class="col col-right" v-if="digital_applications.case_study.text_2">
					<div v-html="digital_applications.case_study.text_2"></div>
				</div>
			</div>
			<thumbnailer
				v-if="digital_applications.case_study.image.id"
				:css-classes="'image'"
				:filer-file-id="digital_applications.case_study.image.id"
				:size="'727x0'"
				:size_xs="'440x0'"
				:key="digital_applications.case_study.image.id"
			/>
		</div>

		<div class="apps-actions">
			<a
				:href="digital_applications.case_study.case_study_url"
				class="highlight-btn"
				v-html="linebreaksbr(digital_applications.case_study.case_study_title)"
				><!-- Download<br> App Here --></a
			>
		</div>
	</section>
</template>

<script>
import reframe from "reframe.js/dist/reframe.js";
import commonMixins from "@/mixins/common-mixins.js";

export default {
	components: {
		reframe
	},
	mixins: [commonMixins],

	data() {
		return {
			digital_applications: null
		};
	},
	computed: {
		subtitles_array() {
			return this.digital_applications.subtitle.split("\n");
		}
	},

	watch: {
		digital_applications: function (to, from) {
			this.$nextTick(() => {
				reframe(this.$refs.reframeVideo);
			});
		}
	},
	async mounted() {
		const res = await this.$api.digital_applications.digital_applications_list();
		if (res.status !== 200) {
			throw res;
		}
		if (!res.obj.published) {
			this.$router.push({ name: "404" });
		}
		this.digital_applications = res.obj;
	}
};
</script>