import Vue from "vue";
import App from "./App.vue";

import store from "@/store/index.js";
import router from "@/router/index.js";
import i18n from "@/i18n/index.js";
import VueLocalStorage from "vue-localstorage";

import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import FilerThumbnailer from "@/components/FilerThumbnailer";


// Bootstrap.
// import { BootstrapVue } from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
// import Vuelidate from "vuelidate";

// Datetimepicker.
// import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

// API.
import ApiService from "@/services/api.service.js";

// Local.
import "./assets/styles/screen.sass";

// Dynamic Routes.
import AboutUs from "@/views/AboutUs";

async function init() {
	await ApiService.init();

	Vue.prototype.$api = ApiService.api;
	Vue.prototype.$apiDefs = ApiService.definitions;

	// Vue.use(BootstrapVue);
	// Vue.use(Vuelidate);

	Vue.use(VueLocalStorage, {
		name: "ls" //Access the local storage with this.$ls (It's shorter).
	});

    Vue.prototype.$_ = { has, isEmpty };
    Vue.component("thumbnailer", FilerThumbnailer);

	Vue.config.devtools = true;
}

init().then(async () => {
	// const result = await ApiService.api.routes.routes_list();

	// let extraRoutes = [];
	// for (let route of result.obj) {
	// 	for (let slug in route.slugs) {
	// 		let r = {
	// 			path: `/${route.slugs[slug]}`,
	// 			name: `${route.name}_${slug}`,
	// 			component: () => import(`@/views/${route.component}`),
	// 			meta: {
	// 				locale: slug
	// 			}
	// 		};
	// 		extraRoutes.push(r);
	// 	}
	// 	let r = {
	// 		path: `/${route.slugs["en"]}`,
	// 		name: `${route.name}`,
	// 		meta: {
	// 			locale: "en",
	// 			base_translatable_url: true
	// 		}
	// 	};
	// 	extraRoutes.push(r);
	// }
	// console.log(extraRoutes);

	// router.addRoutes(extraRoutes);

	store.dispatch("languages/getLanguages");
	store.dispatch("common/getCommonConfigurations");

	if (!window.localStorage.getItem("current_language")) {
		window.localStorage.setItem("current_language", "en");
	}
	i18n.locale = window.localStorage.getItem("current_language");

	let routeRedirect = {
		path: ``,
		redirect: `/${i18n.locale}`,
		meta: {}
	};
	router.addRoutes([routeRedirect]);

	let route404 = {
		path: "/404",
		redirect: `/${i18n.locale}/404`,
		meta: {}
	};
	router.addRoutes([route404]);

	let routeLocale404 = {
		path: "/:lng/404",
		name: "404",
		component: () => import("@/views/Error404"),
		meta: {}
	};
	router.addRoutes([routeLocale404]);

	var app = new Vue({
		el: "#app",
		store,
		router,
		i18n,
		components: {
			app: App
		}
	});
	app.$mount("#app");
});
