<template>
    <picture :class="cssClasses" v-if="srcUrl">
        <source v-if="srcUrl_xs" v-bind:srcset="srcUrl_xs" media="(max-width: 479px)" alt="">
        <source v-if="srcUrl_sm" v-bind:srcset="srcUrl_sm" media="(max-width: 767px)" alt="">
        <source v-if="srcUrl_md" v-bind:srcset="srcUrl_md" media="(max-width: 1199px)" alt="">
        <source v-bind:srcset="srcUrl">
        <img v-bind:src="srcUrl" alt="">
    </picture>
</template>

<script>
    import commonMixins from "@/mixins/common-mixins.js";

    export default {
        name: "FilerThumbnailer",
        mixins: [commonMixins],

        data() {
            return {
                // // TODO: srcList should be an object array with
                // // all necessary parameters needed to create srcsets
                // srcList: [],
                srcUrl: undefined,
                srcUrl_xs: undefined,
                srcUrl_sm: undefined,
                srcUrl_md: undefined,
            };
        },

        props: {
            filerFileId: {
                type: Number,
                required: true
            },
            size: {
                type: String,
                required: true,
                validator: function (value) {
                    // The value must have an 'x' to separate between width+height
                    return value.includes("x");
                }
            },
            size_xs: {
                type: String,
                required: false,
                validator: function (value) {
                    // The value must have an 'x' to separate between width+height
                    return value.includes("x");
                }
            },
            size_sm: {
                type: String,
                required: false,
                validator: function (value) {
                    // The value must have an 'x' to separate between width+height
                    return value.includes("x");
                }
            },
            size_md: {
                type: String,
                required: false,
                validator: function (value) {
                    // The value must have an 'x' to separate between width+height
                    return value.includes("x");
                }
            },
            cssClasses: {
                type: String,
                required: false,
            },
            crop: {
                type: Boolean,
                required: false,
                default: false
            },
            upscale: {
                type: Boolean,
                required: false,
                default: false
            },
        },

        async mounted() {
            if(this.size_xs) {
                this.srcUrl_xs = await this.getThumbnailUrl(this.filerFileId, this.size_xs, this.crop, this.upscale);
            }
            if(this.size_sm) {
                this.srcUrl_sm = await this.getThumbnailUrl(this.filerFileId, this.size_sm, this.crop, this.upscale);
            }
            if(this.size_md) {
                this.srcUrl_md = await this.getThumbnailUrl(this.filerFileId, this.size_md, this.crop, this.upscale);
            }
            this.srcUrl = await this.getThumbnailUrl(this.filerFileId, this.size, this.crop, this.upscale);
        },
    };
</script>