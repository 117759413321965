import ApiService from "@/services/api.service.js";

const state = {
	conf: undefined
};
const getters = {
	conf(state) {
		return state.conf;
	}
};
const mutations = {
	setCommonConfigurations(state, conf) {
		state.conf = conf;
	}
};
const actions = {
	getCommonConfigurations: async function({ commit }) {
		try {
			const result = await ApiService.api.global_conf.global_conf_get();
			if (result.status !== 200) {
				throw result;
			}

			commit("setCommonConfigurations", result.obj);
		} catch (err) {
			console.log(err);
			commit("setCommonConfigurations", null);
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
