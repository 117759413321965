import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/i18n/messages.js";


Vue.use(VueI18n);
export const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: messages
});

export default i18n;
