<template>
	<section class="page-content photostudio-content">
		<div class="loader" v-bind:class="{ inactive: loaded }"></div>
		<div
			v-masonry
			class="photostudio-list"
			ref="list"
			transition-duration="0.5s"
			stagger="0.03s"
			item-selector=".photostudio-item"
		>
			<div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit">
				<div v-for="(item, index) in photostudio_loaded" :key="index">
					<div v-masonry-tile class="photostudio-item">
						<a href="#" v-if="item.images" v-on:click="openModal(item.id, index, $event)">
							<picture class="image">
								<img v-bind:src="item.images[item.randomImageIdx].image_thumb_small" alt />
							</picture>
							<!-- <div class="image" v-bind:style="{ backgroundImage: 'url(' + randomImage(item.images, randomNumber).image + ')' }"></div> -->
						</a>
					</div>
					<div class="photostudio-modal" :data-modal="index">
						<a href="#" class="close-modal" v-on:click="closeModal(index, $event)"></a>
						<div class="loader-modal" v-bind:class="{ inactive: loaded_modal }"></div>
						<div class="container" v-if="gallery">
							<tiny-slider
								:nav="false"
								:controls-text="['', '']"
								:mouse-drag="true"
								:startIndex="item.randomImageIdx"
							>
								<div v-for="(slide, slideIndex) in gallery" :key="slideIndex" ref="slides">
									<picture>
										<img v-bind:src="slide.src" alt />
									</picture>
									<!-- <thumbnailer
                                        :css-classes="'image'"
                                        :filer-file-id="slide.image.id"
                                        :size="'3000x0'"
                                        :size_xs="'480x0'"
                                        :size_sm="'768x0'"
                                        :size_md="'1200x0'"
                                        :key="slide.id" /> -->
									<div class="caption" v-if="slide.title || slide.description">
										<h3 v-if="slide.title" v-html="slide.title"></h3>
										<p v-if="slide.description" v-html="slide.description"></p>
									</div>
								</div>
							</tiny-slider>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import VueTinySlider from "vue-tiny-slider";
import { VueMasonryPlugin } from "vue-masonry";
import infiniteScroll from "vue-infinite-scroll";

Vue.use(VueMasonryPlugin);
Vue.use(infiniteScroll);

export default {
	components: {
		"tiny-slider": VueTinySlider
	},

	data() {
		return {
			loaded: false,
			loaded_modal: false,
			photostudio_loaded: [],
			loadmore_limit: 9,
			loadmore_busy: false,
			gallery: undefined
		};
	},

	async created() {
		await this.$api.photostudio.photostudio_list_page().then((res) => {
			if (res.status !== 200) {
				throw res;
			}
			if (!res.obj.published) {
				this.$router.push({ name: "404" });
			}
		});
		this.get_photostudio_list().then((response) => {
			this.loaded = true;
			this.loadMore();
		});

		window.addEventListener("resize", this.setCaptionWidth);
	},
	destroyed() {
		window.removeEventListener("resize", this.setCaptionWidth);
	},
	computed: {
		...mapGetters(["photostudio"])
	},
	methods: {
		...mapActions(["get_photostudio_list"]),

		getSlideshow: function (galleryId) {
			this.$api.photostudio
				.photostudio_read({
					id: galleryId
				})
				.then(async (res) => {
					if (res.status !== 200) {
						throw res;
					}

					let gallery = [];
					for (const slide of res.obj.images) {
						let slideObj = {
							id: slide.id,
							title: slide.title,
							description: slide.description,
							src: undefined
						};
						let image_thumb_big = await this.$api.get_thumb.get_thumb_read({
							id: slide.image.id,
							size: "3000x0",
							crop: false,
							upscale: false
						});
						slideObj.src = image_thumb_big.obj.url;
						gallery.push(slideObj);
					}
					this.gallery = gallery;
					this.loaded_modal = true;
					// this.setCaptionWidth();
					this.$nextTick(() => {
						this.setCaptionWidth();
					});
					return gallery;
				});
		},

		destroySlideshow: function () {
			this.gallery = undefined;
			this.loaded_modal = false;
		},

		openModal: function (galleryId, modalIndex, event) {
			event.preventDefault();
			this.getSlideshow(galleryId);

			// select modal and add opened class
			const modal = document.querySelectorAll("[data-modal='" + modalIndex + "']");
			modal[0].classList.add("opened");

			// disable scroll
			const doc = document.documentElement;
			const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
			const htmlElem = document.getElementsByTagName("HTML")[0];
			htmlElem.classList.add("noscroll");
			htmlElem.style.top = "-" + scrollTop + "px";
		},
		closeModal: function (modalIndex, event) {
			event.preventDefault();

			// select modal and remove opened class
			const modal = document.querySelectorAll("[data-modal='" + modalIndex + "']");
			modal[0].classList.remove("opened");
			this.destroySlideshow();

			// enable scroll
			const htmlElem = document.getElementsByTagName("HTML")[0];
			const scrollTop = parseInt(htmlElem.style.top);
			htmlElem.classList.remove("noscroll");
			htmlElem.scrollTop = -scrollTop;
		},
		setCaptionWidth: function () {
			if (this.$refs.slides) {
				this.$refs.slides.forEach(function (elem) {
					var image = elem.getElementsByTagName("img");

					if (image.length) {
						image[0].onload = function () {
							var captionElem = elem.getElementsByClassName("caption");
							var caption = captionElem[0];
							if (captionElem.length) {
								caption.style.width = this.width + "px";
							}
						};
						if (image[0].complete && image[0].naturalWidth != 0) {
							image[0].onload();
						}
					}
				});
			}
		},
		loadMore() {
			// Infinite scroll
			this.loadmore_busy = true;

			const append = this.photostudio.slice(
				this.photostudio_loaded.length,
				this.photostudio_loaded.length + this.loadmore_limit
			);
			this.photostudio_loaded = this.photostudio_loaded.concat(append);
			this.loadmore_busy = false;
		}
	}
};
</script>
