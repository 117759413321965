<template>
	<section>
		{{ lngs }}
		{{ about }}
		{{ $t("hello") }}
		<language-select></language-select>
	</section>
</template>

<script>
export default {
	components: {
		"language-select": require("@/components/LanguageSelect").default
	},
	data() {
		return {
			lngs: [],
			about: null
		};
	},
	async mounted() {
		try {
			const result = await this.$api.lngs.lngs_list();
			if (result.status !== 200) {
				throw result;
			}

			this.$data.lngs = result.obj;
		} catch (err) {
			console.log(err);
		}

		try {
			const result = await this.$api.about.about_list();
			if (result.status !== 200) {
				throw result;
			}

			this.$data.about = result.obj;
		} catch (err) {
			console.log(err);
		}
	}
};
</script>
