<template>
	<ul class="main-menu">
		<li v-bind:style="setPosition(540, 254)">
			<router-link
				:to="{ name: 'about_us', params: { lng: $i18n.locale } }"
				:data-color="'blue'"
				v-on:click.native="$emit('toggle-nav')"
			>
				<span class="icon"></span>
				<span class="label">About</span>
				<!-- Este titulo tem de vir do backend :( -->
			</router-link>
		</li>
		<li v-bind:style="setPosition(0, 40)">
			<router-link
				:to="{ name: 'atelier', params: { lng: $i18n.locale } }"
				:data-color="'pink'"
				v-on:click.native="$emit('toggle-nav')"
			>
				<span class="icon"></span>
				<span class="label">Atelier</span>
				<!-- Este titulo tem de vir do backend :( -->
			</router-link>
		</li>
		<li v-bind:style="setPosition(-681, -152)">
			<router-link
				:to="{ name: 'design_reflection', params: { lng: $i18n.locale } }"
				:data-color="'yellow'"
				v-on:click.native="$emit('toggle-nav')"
			>
				<span class="icon"></span>
				<span class="label">Design Reflection</span>
				<!-- Este titulo tem de vir do backend :( -->
			</router-link>
		</li>
		<li v-bind:style="setPosition(516, -391)">
			<router-link
				:to="{ name: 'digital_applications_landing', params: { lng: $i18n.locale } }"
				:data-color="'green'"
				v-on:click.native="$emit('toggle-nav')"
			>
				<span class="icon"></span>
				<span class="label">Digital Applications</span>
				<!-- Este titulo tem de vir do backend :( -->
			</router-link>
		</li>
		<li v-bind:style="setPosition(-510, 350)">
			<router-link
				:to="{ name: 'photo_studio', params: { lng: $i18n.locale } }"
				:data-color="'red'"
				v-on:click.native="$emit('toggle-nav')"
			>
				<span class="icon"></span>
				<span class="label">Photo Studio</span>
				<!-- Este titulo tem de vir do backend :( -->
			</router-link>
		</li>
	</ul>
</template>

<script>
export default {
	name: "MainMenu",
	props: {
		withMenuPosition: Boolean,
		showMenu: Boolean
	},
	data: function () {
		return {
			windowSize: {
				width: 0,
				height: 0
			}
		};
	},
	created() {
		if (this.withMenuPosition) {
			this.getWindowSize();
			window.addEventListener("resize", this.getWindowSize);
		}
	},
	destroyed() {
		if (this.withMenuPosition) {
			window.removeEventListener("resize", this.getWindowSize);
		}
	},
	methods: {
		getWindowSize: function () {
			this.windowSize.width = window.innerWidth;
			this.windowSize.height = window.innerHeight;
		},
		getPositionOnGrid: function (position, gridSize) {
			// Round number up to the nearest multiple of grid size
			// https://stackoverflow.com/questions/3254047/round-number-up-to-the-nearest-multiple-of-3
			if (position > 0) {
				return Math.ceil(position / gridSize) * gridSize;
			} else if (position < 0) {
				return Math.floor(position / gridSize) * gridSize;
			} else {
				return 0;
			}
		},
		setPosition: function (x, y) {
			if (!this.withMenuPosition) {
				return false;
			}

			if (this.showMenu) {
				if (x || y) {
					const itemX = x;
					const itemY = y;
					const windowHeight = this.windowSize.height;
					const windowWidth = this.windowSize.width;

					// reference sizes from layouts
					const gridSize = 34;
					const baseHeight = 1080;
					const baseWidth = 1920;

					// calculate positions
					const itemLeft = this.getPositionOnGrid((itemX * windowWidth) / baseWidth, gridSize);
					const itemTop = this.getPositionOnGrid((itemY * windowHeight) / baseHeight, gridSize);

					// bind styles with margins because
					// positions are measured from layout center
					return {
						marginLeft: itemLeft + "px",
						marginTop: itemTop + "px"
					};
				}
			}
		}
	}
};
</script>
