<template>
	<section class="page-content articles-content">
		<div class="articles-intro" v-if="object">
			<router-link
				v-if="digital_apps_page_enabled"
				:to="{ name: 'digital_applications', params: { lng: $i18n.locale } }"
			>
				<img :src="object.image.url" :alt="object.title" />
			</router-link>
			<img v-else :src="object.image.url" :alt="object.title" />
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			object: undefined,
			digital_apps_page_enabled: undefined
		};
	},
	async created() {
		await this.$api.digital_applications_landing.digital_applications_landing_list().then(async (res) => {
			if (res.status !== 200) {
				throw res;
			}
			if (!res.obj.published) {
				await this.$api.digital_applications.digital_applications_list().then((response) => {
					if (response.status !== 200) {
						console.log(response);
						this.$router.push({ name: "404" });
					}

					if (response.obj.published) {
						this.$router.push({ name: "digital_applications", params: { lng: this.$i18n.locale } });
					} else {
						this.$router.push({ name: "404" });
					}
				});
			}
			this.digitalApplicationsPagePublished();
			this.object = res.obj;
		});
	},
	methods: {
		async digitalApplicationsPagePublished() {
			await this.$api.digital_applications.digital_applications_list().then(async (res) => {
				if (res.status !== 200) {
					console.log(res);
				}
				this.digital_apps_page_enabled = res.obj.published;
			});

			return undefined;
		}
	}
};
</script>